const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    fill="none"
    cursor="pointer"
    {...props}
  >
    <circle cx={27} cy={27} r={27} fill="#F5F5F5" />
    <mask
      id="a"
      width={54}
      height={54}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={27} cy={27} r={27} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#000"
        d="m32.468 25.177-8.37-8.629a1.776 1.776 0 0 0-2.566 0 1.912 1.912 0 0 0 0 2.646l7.086 7.306-7.086 7.306a1.912 1.912 0 0 0 0 2.646c.708.73 1.857.73 2.566 0l8.37-8.63a1.901 1.901 0 0 0 0-2.645Z"
      />
    </g>
  </svg>
);

export default RightArrow;
