import client1 from "../assets/images/client-1.png";
import client2 from "../assets/images/client-2.png";
import client3 from "../assets/images/client-3.png";
import client4 from "../assets/images/client-4.png";
import client5 from "../assets/images/client-5.png";
import client6 from "../assets/images/client-6.png";
import client7 from "../assets/images/client-7.png";
import client8 from "../assets/images/client-8.png";
import client9 from "../assets/images/client-9.png";
import client10 from "../assets/images/client-10.png";
import client11 from "../assets/images/client-11.png";
import client12 from "../assets/images/client-12.png";
import client13 from "../assets/images/client-13.png";
import client14 from "../assets/images/client-14.jpg";
import client15 from "../assets/images/client-15.jpg";
import Product1 from "../assets/images/product-1.jpg";
import Product2 from "../assets/images/product-2.png";
import Product3 from "../assets/images/product-3.jpg";
import Product4 from "../assets/images/product-4.jpg";
import Product5 from "../assets/images/product-5.jpg";
import Product6 from "../assets/images/product-6.jpg";
import Product7 from "../assets/images/product-7.jpg";
import Product8 from "../assets/images/product-8.jpg";
import Product9 from "../assets/images/product-9.jpg";
import Product10 from "../assets/images/product-10.jpg";

export const ClientData = [
  {
    image: client1,
  },
  {
    image: client2,
  },
  {
    image: client3,
  },
  {
    image: client4,
  },
  {
    image: client5,
  },
  {
    image: client6,
  },
  {
    image: client7,
  },
  {
    image: client8,
  },
  {
    image: client9,
  },
  {
    image: client10,
  },
  {
    image: client11,
  },
  {
    image: client12,
  },
  {
    image: client13,
  },
  {
    image: client14,
  },
  {
    image: client15,
  },
];

export const ProductData = [
  {
    image: Product1,
    title: "Online UPS",
  },
  {
    image: Product2,
    title: "APSC (Automatic Phase Sequence Corrector)",
  },
  {
    image: Product3,
    title: "Surge Suppressor Panels",
  },
  {
    image: Product4,
    title: "CVCF ( Constant Voltage Constant Frequency Device )",
  },
  {
    image: Product5,
    title: "Servo Voltage Stabilizer",
  },
  {
    image: Product6,
    title: "Harmonic Filters",
  },
  {
    image: Product7,
    title: "busbar-temperature-monitoring",
  },
  {
    image: Product8,
    title: "LED STANDIES",
  },
  {
    image: Product9,
    title: "STATIC BYPASS MODULES",
  },
  {
    image: Product10,
    title: "AIR PURIFIERS",
  },
];

export const ProductTypes = [
  {
    title: "Precision air Conditioning",
  },
  {
    title: "all kind of signages",
  },
  {
    title: "APSC (Automatic Phase Sequence Corrector)",
  },
  {
    title: "Online UPS",
  },

  {
    title: "Surge Suppressor Panels",
  },
  {
    title: "CVCF ( Constant Voltage Constant Frequency Device )",
  },
  {
    title: "Servo Voltage Stabilizer",
  },
  {
    title: "Harmonic Filters",
  },
  {
    title: "busbar-temperature-monitoring",
  },
  {
    title: "LED STANDIES",
  },
  {
    title: "STATIC BYPASS MODULES",
  },
  {
    title: "AIR PURIFIERS",
  },
  {
    title: "Video wall/ displays",
  },
  {
    title: "Window/split Air conditioners",
  },
];
