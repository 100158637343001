import { styled } from "styled-components";
import StartWrapperBackground from "../assets/images/industryBackground.jpeg";

const StartBackground = StartWrapperBackground;

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${StartBackground});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
  justify-content: center;
  padding: 30px 10px;
  img {
    max-width: 350px;
  }
  .iso_image {
    max-width: 90px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    img {
      max-width: 250px;
    }
    .iso_image {
      max-width: 50px;
    }
  }
`;
export const ImageSection = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
  justify-content: center;
  img {
    max-width: 300px;
  }
  .iso_image {
    max-width: 80px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    img {
      max-width: 250px;
    }
    .iso_image {
      max-width: 50px;
    }
  }
`;

export const LogoSection = styled.div`
  display: grid;
  gap: 3px;
  align-items: center;
  justify-content: center;
`;
export const LogoText = styled.div`
  color: rgb(117, 190, 69);
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  text-align: center;
  font-style: italic;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
export const FooterLogoText = styled.div`
  color: rgb(117, 190, 69);
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
  text-align: center;
  font-style: italic;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
export const HeadContent = styled.div`
  padding: 50px;
  background: #fffceb;
`;
export const Text = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 30px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
export const ExperienceText = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 30px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
export const Content = styled.div`
  padding: 50px;
  @media (max-width: 500px) {
    padding: 30px;
  }
`;
export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px;
`;
export const ProductSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin: 30px;
  @media (max-width: 1050px) {
    grid-template-columns: auto;
  }
`;
export const Button = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #000 50%, #f5f5f5 50%);
  background-size: 99% 200%;
  background-position: 0 100%;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-position 0.3s ease-in-out;
  text-align: center;

  &:hover {
    background-position: 100% 0%;
    color: #fff;
    text-decoration: underline;
  }
`;
export const Title = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 1),
      rgba(100, 200, 200, 1)
    ),
    linear-gradient(
      to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(0, 100, 200, 1)
    );
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  &:hover {
    background-size: 0 0.1em, 100% 0.1em;
  }
`;
export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 70px 0;
  @media (max-width: 500px) {
    margin: 50px 0;
  }
`;
export const SliderContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  @media (max-width: 500px) {
    max-width: 350px;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    gap: 15px;

    .slick-slide {
      img {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 350px;
        height: 300px;
        max-width: 350px;
        margin: 0 auto;
        border-radius: 12px;
        @media (max-width: 500px) {
          height: 250px;
        }
      }
      width: 350px !important;
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 10;
    top: 40% !important;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }
  }
`;
export const ProductName = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  font-weight: bold;
`;
export const ProductContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  @media (max-width: 500px) {
    max-width: 350px;
  }

  .slick-track {
    display: flex;
    gap: 15px;

    .slick-slide {
      img {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 350px;
        height: 300px;
        max-width: 350px;
        margin: 0 auto;
        border-radius: 12px;
      }
      width: 350px !important;
      @media (max-width: 500px) {
        width: 300px !important;
        img {
          width: 200px;
          height: 200px;
          max-width: 200px;
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 10;
    top: 40% !important;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }
  }
`;
export const ProductButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #000 50%, #f5f5f5 50%);
  background-size: 99% 200%;
  background-position: 0 100%;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-position 0.3s ease-in-out;
  text-align: center;
  color: #000;
  &:hover {
    background-position: 100% 0%;
    color: #fff;
  }
  @media (max-width: 500px) {
    max-width: 300px;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  width: max-content;
  iframe {
    height: 500px;
    width: 600px;
    @media (max-width: 500px) {
      height: 250px;
      width: 300px;
    }
  }
`;
export const ContactSection = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  @media (max-width: 1050px) {
    display: grid;
    gap: 30px;
    justify-content: center;
    justify-items: center;
  }
`;
export const ContactContainer = styled.div`
  padding: 10px 30px;
  display: grid;
  gap: 20px;
`;

export const ContactTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
`;
export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  @media (max-width: 500px) {
    font-size: 14px;
  }
  img {
    max-width: 50px;
  }
`;
export const Value = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
export const Link = styled.a`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  width: max-content;
`;
export const GetButton = styled.div`
  position: absolute;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
`;

export const FooterContent = styled.div`
  padding: 50px;
  color: #fff;
  background: #333333;
  display: grid;
  gap: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const FooterText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
export const EnlistSection = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    max-width: 250px;
  }
  @media (max-width: 1050px) {
    display: grid;
    gap: 30px;
    justify-content: center;
    justify-items: center;
  }
`;
export const CertificateSection = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    max-width: 350px;
  }
  @media (max-width: 1050px) {
    display: grid;
    gap: 30px;
    justify-content: center;
    justify-items: center;
  }
  @media (max-width: 500px) {
    img {
      max-width: 300px;
    }
  }
`;
