import { useRef } from "react";
import Slider from "react-slick";
import {
  Container,
  Header,
  HeadContent,
  Content,
  ButtonSection,
  Button,
  Text,
  AboutSection,
  Title,
  ExperienceText,
  SliderContainer,
  ProductName,
  ProductContainer,
  ProductSection,
  ProductButton,
  GetButton,
  MapContainer,
  ContactSection,
  ContactContainer,
  Label,
  Value,
  Link,
  FooterContent,
  FooterText,
  EnlistSection,
  CertificateSection,
  ImageSection,
  LogoSection,
  LogoText,
  FooterLogoText,
} from "../styles/home";
import Logo from "../assets/images/bmr-logo.png";
import Address from "../assets/images/address.png";
import Phone from "../assets/images/phone.png";
import Email from "../assets/images/email.png";
import NSIC from "../assets/images/nsic.png";
import ISO from "../assets/images/iso.png";
import MSME from "../assets/images/msme.png";
import CPWD from "../assets/images/client-8.png";
import Cert1 from "../assets/images/certificate-1.jpeg";
import Cert2 from "../assets/images/certificate-2.jpeg";
import Cert3 from "../assets/images/certificate-3.jpeg";
import PrevArrow from "../assets/svg/leftArrowIcon";
import RightArrow from "../assets/svg/rightArrowIcon";
import { ClientData, ProductData, ProductTypes } from "../views/data";

const Home = () => {
  const aboutRef = useRef(null);
  const missionRef = useRef(null);
  const clientRef = useRef(null);
  const experienceRef = useRef(null);
  const productRef = useRef(null);
  const contactRef = useRef(null);
  const expertiseref = useRef(null);
  const pledgeRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref?.current) {
      const offsetTop =
        ref.current.getBoundingClientRect().top + window.scrollY;
      const offset = 50;

      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth",
      });
    }
  };
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <RightArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const openGoogleMaps = (address) => {
    const baseUrl = "https://www.google.com/maps/dir/?api=1";
    const destination = encodeURIComponent(address);
    const url = `${baseUrl}&destination=${destination}`;
    window.open(url, "_blank");
  };
  return (
    <Container>
      <Header>
        <LogoSection>
          <img src={Logo} alt="logo" />
          <LogoText>Technology Integration Partner</LogoText>
        </LogoSection>
        <img src={ISO} alt="iso-certified" className="iso_image" />
      </Header>
      <HeadContent>
        <Text>
          BMR commenced its operations in 2021, with its first Karnal ( Haryana)
          office and since this time We have developed a strong, trustworthy
          reputation for quality and reliability in the field of Power
          Conditioning Services. The company has a presence in most of north
          India covering various sectors such as the central government, state
          governments, and corporate houses.
        </Text>
      </HeadContent>
      <Content>
        <ButtonSection>
          <Button onClick={() => scrollToSection(aboutRef)}>About us</Button>
          <Button onClick={() => scrollToSection(missionRef)}>
            Our Mission
          </Button>
          <Button onClick={() => scrollToSection(clientRef)}>
            Our Clients
          </Button>
          <Button onClick={() => scrollToSection(experienceRef)}>
            Our Experience
          </Button>
          <Button onClick={() => scrollToSection(productRef)}>
            Our Products
          </Button>
          <Button onClick={() => scrollToSection(expertiseref)}>
            Our Expertise
          </Button>
          <Button onClick={() => scrollToSection(pledgeRef)}>Our Pledge</Button>
          <Button onClick={() => scrollToSection(contactRef)}>
            Contact Us
          </Button>
        </ButtonSection>
        <AboutSection ref={aboutRef}>
          <Title>About Us</Title>
          <Text>
            With a comprehensive range of services across diverse industries, we
            are one of India&apos;s leading System Integration and Power
            Conditioning Equipment servicing company. We committed to using best
            practice knowledge and global experience and take a proactive
            continuous improvement approach to broaden and enhance the services
            to Clients. Our representation enables localized service at
            consistent standards. Our strengths are underpinned by a global
            infrastructure, a highly skilled and experienced workforce, and a
            diverse range of quality services. Our business unit provides Power
            Backup, Cooling & Power conditioning solutions. By Our Premium
            services we developed a reputation for its high standards. It is
            company policy to offer equipment and services of the highest
            quality and reliability. This is backed up with specialist design,
            technical training and consultancy advice. Features that ease
            installation and maintenance are incorporated into the equipment
            selection and with careful design, rigorous testing and strict
            quality control ensure that our installed systems meet and exceed
            the specified parameters. The company is executing prestigious
            projects. BMR is an ISO 9001:2015 certified company in its
            respective field of operations having headquarters in Karnal
            (Haryana) and branch office in Delhi . We have a good team with 9+
            years of experience in providing End to End customized solutions and
            services for the entire organizational setup. Our brand character is
            customized, professional, immediate, helpful and up-to-date with the
            latest technology providers. We have carefully analyzed the service
            needs, both of our equipment and our customers.
          </Text>
        </AboutSection>
        <AboutSection ref={missionRef}>
          <Title>Our Mission</Title>
          <Text>
            To build value, performance and leadership into Power Conditioning &
            cooling solutions, responsibility, respect and determination are
            key. Our motto is to shift our esteemed customer in a comfort zone
            by giving excellent & timely services
          </Text>
        </AboutSection>
        <AboutSection ref={clientRef}>
          <Title>Our Clients</Title>

          <SliderContainer>
            <Slider {...settings}>
              {ClientData?.map((item, index) => (
                <img src={item?.image} alt="client-image" key={index} />
              ))}
            </Slider>
          </SliderContainer>
        </AboutSection>
        <AboutSection ref={experienceRef}>
          <Title>Our Experience</Title>
          <ExperienceText>
            Experience of 3 years in the field of following facilties:
            <ul>
              <li>
                Indoor & Outdoor Electrical Installation work of Industrial,
                Commercial, Educational, government buildings Projects.
              </li>
              <li>
                All types of Power & Distribution LT cabling with special
                end-termination work.
              </li>
              <li>SITC of UPS Systems up to 300 KVA with battery backup.</li>
              <li>SITC of Precision Air Conditioning up to 56 Tr.</li>
              <li>
                Solar photovoltaic system for generation of electrical power,
                solar street lighting, Solar Emergency Lighting
              </li>
              <li>
                SITC of Transient Voltage Surge Suppressor System up to 5000 kA
              </li>
              <li>
                SITC of Automatic phase sequence correctors up to 2000 Amps
              </li>
              <li>
                AMC work of Window AC, Split AC, Precision AC, UPS, Chandelier
              </li>
            </ul>
          </ExperienceText>
        </AboutSection>
        <AboutSection ref={productRef}>
          <Title>Our Products</Title>

          <ProductContainer>
            <Slider {...settings}>
              {ProductData?.map((item, index) => (
                <div>
                  <img src={item?.image} alt="client-image" key={index} />
                  <ProductName>{item.title}</ProductName>
                </div>
              ))}
            </Slider>
          </ProductContainer>
        </AboutSection>
        <AboutSection ref={expertiseref}>
          <Title>Our Expertise</Title>
          <Text>We deals in SITC & AMC of</Text>
          <ProductSection>
            {ProductTypes?.map((item, index) => (
              <ProductButton key={index}>{item.title}</ProductButton>
            ))}
          </ProductSection>
        </AboutSection>
        <AboutSection ref={contactRef}>
          <Title>Contact Us</Title>
          <ContactSection>
            <ContactContainer>
              <Label>
                <img src={Address} alt="logo" />
              </Label>
              <Label>Address</Label>

              <Value>
                # 362-A, Gandhi Chowk, Sadar Bazar, Karnal -132001, Haryana
              </Value>
              <Label>
                <img src={Phone} alt="logo" />
              </Label>
              <Label>Phone</Label>
              <Value>
                <Link href="tel:+91-8800725100">+91-8800725100, </Link>
                <Link href="tel:9990808599">9990808599, </Link>
                <Link href="tel:8585850303">8585850303, </Link>
                <Link href="tel:+911844008158">+911844008158</Link>
              </Value>
              <Label>
                <img src={Email} alt="logo" />
              </Label>
              <Label>Email</Label>

              <Link href="mailto:contact@technologiesbmr.com">
                contact@technologiesbmr.com
              </Link>
              <Link href="mailto:technologiesbmr@gmail.com">
                technologiesbmr@gmail.com
              </Link>
            </ContactContainer>
            <MapContainer>
              <GetButton
                onClick={() =>
                  openGoogleMaps(
                    "#362-A, Gandhi Chowk, Sadar Bazar, Karnal, Haryana"
                  )
                }
              >
                Get Directions
              </GetButton>

              <iframe
                width="600"
                height="500"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=%23Gandhi%20Chowk%2C%20Sadar%20Bazar%2C%20Karnal%2C%20Haryana&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </MapContainer>
          </ContactSection>
        </AboutSection>
        <AboutSection>
          <Title>Enlisted by</Title>
          <EnlistSection>
            <img src={NSIC} alt="logo" />
            <img src={CPWD} alt="logo" />
            <img src={MSME} alt="logo" />
          </EnlistSection>
        </AboutSection>
        <AboutSection ref={pledgeRef}>
          <Title>Our Pledge</Title>
          <CertificateSection>
            <img src={Cert2} alt="logo" />
            <img src={Cert1} alt="logo" />
            <img src={Cert3} alt="logo" />
          </CertificateSection>
        </AboutSection>
      </Content>
      <FooterContent>
        <ImageSection>
          <LogoSection>
            <img src={Logo} alt="logo" />
            <FooterLogoText>Technology Integration Partner</FooterLogoText>
          </LogoSection>
          <img src={ISO} alt="iso-certified" className="iso_image" />
        </ImageSection>
        <FooterText>
          # 362-A, Gandhi Chowk, Sadar Bazar, Karnal -132001, Haryana
        </FooterText>
        <FooterText>COPYRIGHT © 2024 BMR - ALL RIGHTS RESERVED.</FooterText>
      </FooterContent>
    </Container>
  );
};

export default Home;
